/* @font-face {
  font-family: "Open Sans";
  font-style: "normal";
  font-weight: 400;
  src: local("Open Sans Regular"),
    url("./fonts/OpenSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: "normal";
  font-weight: 400;
  src: local("Source Sans Pro Regular"),
    url("./fonts/SourceSansPro-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: "italic";
  font-weight: 600;
  src: local("Source Sans Semibold Pro"),
    url("./fonts/SourceSansPro-SemiBold.woff2") format("woff2");
} */

body {
  height: 100% !important;
  width: 100% !important;
  /* background-color: #000e21 !important; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Source+Sans+Pro:ital@0;1&display=swap'); */
