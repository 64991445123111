.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-amplify-authenticator] {
  max-width: 100%;
  padding-top: 15%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.recharts-cartesian-axis-tick-value {
  font-size: 10px;
}
.recharts-legend-item-text {
  font-size: 16px;
}
.recharts-legend-item {
  display: inline-flex !important;
  align-items: center;
}
.dragging {
  z-index: 999;
  position: relative;
}

.record-wrap {
  position: relative;
}

.record-wrap .record-drag-handle {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.chip-drag-handle {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: -5px;
}

.hidden {
  display: none !important; /* use !important to ensure it hides the component even if other styles might be affecting it */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-pdf__Page.prevPage {
  position: absolute !important;
  z-index: 1;
}

.ql-editor {
  font-size: 18px;
}
